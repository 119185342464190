
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import { mixins } from "vue-class-component";
import TasqDetailsMixin from "@/components/tasqsCommon/TasqDetailsMixin";
import tasqsListModule from "@/store/modules/tasqsListModule";
import GenericMixin from "@/lib/mixins/GenericMixin";
import { formatWellName, getComponent } from "@/utils/helpers";
import TasqJob from "@/interfaces/tasqs/TasqJob";
import tasqProductionDataChartModule from "@/store/modules/tasqProductionDataChartModule";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";
import gptModule from "@/store/modules/gptModule";

@Component({
  components: {
    TasqWellStatusBanner: () => getComponent("tasqs/TasqWellStatusBanner"),
    TasqBatchFeedback: () => getComponent("tasqs/TasqBatchFeedback"),
    GasUplift: () => getComponent("gpt/GasUplift"),
    TasqWellDetails: () => getComponent('tasqs/TasqWellDetails'),
  },
})
export default class TasqsDetailV2 extends mixins(TasqDetailsMixin, GenericMixin) {

  isHoveringGasLift = false



  handleScroll() {
    if (this.$refs.wellSignalViewCy != null) {
      // @ts-ignore
      this.$refs.wellSignalViewCy.handleScroll();
    }

  }

  formatWellName(name) {
    return formatWellName(name);
  }

  isScrolling(){
    console.log('scrolling');
  }

  get disabledDates() {
    let tasqDetailComponent = this;
    return [
      function (date) {
        let startDate = new Date(tasqDetailComponent.action.startDate);
        startDate.setHours(0,0,0,0);
        date.setHours(0,0,0,0);
        return date <= startDate
      }
    ]
  }


  get gridStyles() {
    if (this.showFeedbackMiniView && this.isFeedbackMiniViewHovering) {
      return 'relative grid grid-rows-1';
    }
    if (this.showFeedbackMiniView && !this.isFeedbackMiniViewHovering) {
      return 'tasqs-grid-mini1';
    }
    return 'tasqs-grid';
  }

  get chokeSize() {
    return (
      tasqProductionDataChartModule.chokeSize
    );
  }


  get tasqDataLoadingFeedback(){
    return tasqsListModule.tasqDataLoadingFeedback
  }

  // @Watch("$route.params.id",{immediate: true})
  // async onTasqRouteIdChange(id: string) {
  //   const { wellName } = this.$route.query;
  //   if (id) {
  //     this.showDataLoadingFeedback = true;

  //     tasqsListModule.setTasqDataLoadingFeedback(true);



  //     this.fetchTasq().then(async () => {
  //       tasqsListModule.updateTasqViewsCount({ predictionID: id });
  //       console.log('object');

  //       await this.sleep(300)
  //       tasqsListModule.setTasqDataLoadingFeedback(false);
  //     });

  //     this.padWellsSkipToBeginning();
  //   } else {
  //     tasqsListModule.setActiveTasq("");
  //   }
  //   this.multiTasqType = "";
  // }


  prepareAction(action) {
    this.$emit("prepare-action", action);
  }

  prepareWaitingOnStatus() {
    this.$emit("prepare-waiting-on-status");
  }

  setVerticalListPinnedValue() {
    tasqsListModule.setIsVerticalListUnPinned(!tasqsListModule.isVerticalListUnPinned);
  }

  setVerticalListHovering(state) {
    tasqsListModule.setIsVerticalListHovering(state);
  }


  get showFeedbackMiniView(){
    return tasqFeedbackModule.showFeedbackMiniView
  }

  get gasLiftData(){
    return gptModule.gasLiftData
  }

  getKeyMatches(obj, searchKeys) {
    const keys = Object.keys(obj);

    let result: any = null;
    keys.forEach((key) => {
      if (key) {
        if (key.toLowerCase().includes(searchKeys.toLowerCase())) {
          result = key;
        }
      }
    });

    return result;
  }


  get wellDefermentPercentage() {
    return gptModule.wellDefermentDetails(this.tasq.wellName);
  }

  get wellDefermentValue() {
    return this.wellDefermentPercentage && !Number.isNaN(this.wellDefermentPercentage.Deferment) ? Math.abs(this.wellDefermentPercentage.Deferment) : this.tasq.defermentValue;
  }

  get wellDefermentPercentageValue() {

  if (this.wellDefermentPercentage && !Number.isNaN(this.wellDefermentPercentage.Deferment_Percentage)) {
    const defermentPercentage = Math.round(Math.abs(this.wellDefermentPercentage.Deferment_Percentage));
    return defermentPercentage > 100 ? 100 : defermentPercentage;
  }
    return this.tasq.defermentPercentage;;
}

  get gasLiftTableValveStatus() {

    let typeKey = 'type'
    if (this.gasLiftData && this.gasLiftData['data'] && this.gasLiftData['data']['schematics'] && this.gasLiftData['data']['schematics'][0]) {
      const firstObj = this.gasLiftData['data']['schematics'][0][0];
      if (firstObj) {
        typeKey = this.getKeyMatches(firstObj, typeKey);
      }
      let totalCount =  this.gasLiftData['data']['schematics'][0].filter(
        item =>item[typeKey] === 'gas_lift_valve').length
      let openCount = (this.gasLiftData['data']['schematics'][0].filter(
        item =>
          item.Status === 'open' && item[typeKey] === 'gas_lift_valve'
        )

        ).length

      return `${openCount}/${totalCount}`

    }

    return ''
  }


  get isFeedbackMiniViewHovering(){
    return tasqFeedbackModule.isFeedbackMiniViewHovering
  }

}
